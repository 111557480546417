<template>
  <div class="new-center">
    <div style="width: 75%">
      <div class="news-main">
        <div class="news-main-title">{{ newsInfo.newsTitle }}</div>
        <div class="news-main-time">发布时间：{{ newsInfo.createTime }}</div>
        <div class="news-main-content" style="text-align: justify">
          <div
            v-html="newsInfo.newsContent"
            class="ql-editor"
            style="
              width: 100%;
              overflow: auto;
              color: #666666;
              font-size: 16px;
              max-width: px;
              word-break: break-all;
              font-family: 微软雅黑;
              line-height: 28px;
            "
          ></div>
        </div>
      </div>
      <div class="prv-next">
        <div class="prv-text" @click="goNext(preNextData.afterId)">
          <span>上一篇：</span>
          <span class="other-news">{{
            preNextData.afterTitle ? preNextData.afterTitle : "没有了"
          }}</span>
        </div>
        <div class="next-text" @click="goNext(preNextData.beforeId)">
          <span>下一篇：</span>
          <span class="other-news">{{
            preNextData.beforeTitle ? preNextData.beforeTitle : "没有了"
          }}</span>
        </div>
        <!-- <div class="return-button" @click="goBack">返回列表</div> -->
      </div>
    </div>
    <div class="right-news-list">
      <div
        v-for="(item, i) in newsList"
        :key="i"
        class="news-list-item"
        @click="goNext(item.newsId)"
      >
        <div class="news-list-title">{{ item.newsTitle }}</div>
        <div class="news-list-time">发布时间：{{ item.timeType }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNewsNoticeAll,
  getNewsNoticeDetail,
  getNewsNoticeBeforeAndAfter,
} from "../../../js/api/index";
export default {
  data() {
    return {
      preNextData: "", //上一页下一页数据
      newsInfo: "", //新闻详情
      pageInfo: {
        pageNum: 1,
        pageSize: 15,
        type: 1,
      },
      newsList: [],
    };
  },
  computed: {},
  methods: {
    //初始化新闻信息
    initNewsData() {
      getNewsNoticeAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = this.splitTime(element.createTime);
        }
        this.newsList = list;
      });
    },
    checkRouter() {
      let id = this.$route.query.id;
      getNewsNoticeDetail(id).then((res) => {
        console.log(res)
        this.newsInfo = res.data;
      });
      getNewsNoticeBeforeAndAfter(id).then((res) => {
        if (res.data) {
          this.preNextData = res.data;
        } else {
          this.preNextData = "";
        }
      });
    },
    goNext(id) {
      if (!id) return;
      let routeId = this.$route.query.id;
      if (routeId == id) return;
      this.$router.replace({ query: { id: id } });
      this.checkRouter();
    },
    splitTime(time) {
      // 分割日期和时间
      var splitDateTime = time.split(" ");
      var date = splitDateTime[0];
      return date;
    },
  },
  mounted() {
    this.checkRouter();
    this.initNewsData();
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.new-center {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  border-top: 1px solid #dcdcdc;
}
.news-main {
  position: relative;
  padding-right: 80px;
  .news-main-title {
    font-size: 26px;
    font-weight: bold;
    color: #191919;
    padding-bottom: 20px;
  }
  .news-main-time {
    text-align: left;
    font-size: 14px;
    color: #adadad;
  }
  .news-main-content {
    ::v-deep img {
      max-width: 100%;
    }
  }
}
.right-news-list {
  width: 25%;
  .news-list-item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
    cursor: pointer;
    .news-list-title {
      color: #373a41;
      font-size: 15px;
      font-family: 微软雅黑;
      line-height: 15px;
      font-weight: normal;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .news-list-time {
      color: #bfbfbf;
      font-size: 12px;
      line-height: 12px;
    }
  }
}
.prv-next {
  padding-right: 80px;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0;
  .other-news {
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .other-news:hover {
    color: #004da0;
  }
}
</style>